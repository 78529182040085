<template><div></div></template>

<script>
export default {
  name: 'ANZOPTIONDET',

  data() {
    return {};
  },

  methods: {}
};
</script>

<style lang="scss"></style>
